
import { get } from 'superagent'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AdminPage',
  data () {
    return {
      users: []
    }
  },
  methods: {
    async load () {
      const response = await get('https://asd-promo.implude.kr/status')
        .set('implude', 'dimigoelalcnd')
      console.log(response.body)
      this.users = response.body.data
    }
  },
  mounted () {
    this.load()
  }
})

