
import Swal from 'sweetalert2'
import { post } from 'superagent'
import { defineComponent } from 'vue'
const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
const format = /^(\d{2,3})(\d{3,4})(\d{4})$ /

export default defineComponent({
  name: 'App',
  data () {
    return {
      name: '',
      phone: '',
      error: false
    }
  },
  methods: {
    async submit () {
      if (this.error || !this.name) return Swal.fire('잠시만요', '입력값이 올바른지 다시한번 확인해주세요', 'error')
      try {
        const response = await post('https://asd-promo.implude.kr/register')
          .set('implude', 'dimigoelalcnd')
          .send({
            name: this.name,
            phone: this.phone
          })
        if (response.body.success) return Swal.fire('성공!', '서비스가 시작되면 만나요!', 'success')
        else if (response.body.message === 'Already Registered') Swal.fire('이미 등록되었어요', `${this.phone} 전화번호로 이미 등록되어 있어요`, 'error')
        else if (response.body.message === 'Too many requests') Swal.fire('에러', '같은 IP에서 너무 많은 요청이 들어왔어요!<br>1시간뒤 다시 시도해주세요', 'error')
        else if (response.body.message === 'Internal Server Error') Swal.fire('에러', '내부적인 오류가 발생했어요.<br>잠시후 다시 시도해주세요', 'error')
        else if (response.body.message === 'Bad Request') Swal.fire('에러', '무언가 잘못되었어요', 'error')
      } catch (e) {
        return Swal.fire('에러', '내부적인 오류가 발생했어요.<br>잠시후 다시 시도해주세요', 'error')
      }
    },
    privacy () {
      window.open('https://cyan-acai-d47.notion.site/93ecebdb8d57478bae1a36d7366a0536', '_blank')
    }
  },
  watch: {
    phone (value) {
      this.phone = value.replace(format, '$1-$2-$3')
      if (!regex.test(value) || !value) {
        this.error = true
      } else {
        this.error = false
      }
    }
  }
})
